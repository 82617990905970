<template>
    <div class="flex flex-col justify-center sm:px-6 lg:px-8 ccss--landing">
        <div class="sm:mx-auto sm:w-full sm:max-w-md">
            <h2 class="text-center text-3xl font-extrabold">
                {{ $t('page.403') }}
            </h2>
        </div>
    </div>
</template>


<script>
export default {
    name: '403',
}
</script>
